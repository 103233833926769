export const arrayToObject = (array: any[], key: string) =>
	array.reduce((acc, curr) => {
		acc[curr[key]] = curr;
		return acc;
	}, {});


export const sorter = (a: any, b: any, key: string) => {
	return a[key].toLowerCase() > b[key].toLocaleLowerCase() ? 1 : b[key].toLowerCase() > a[key].toLowerCase() ? -1 : 0;
};

export const sortInt = (a: any, b: any, key: string) => {
	return a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;
};

export const checkForNullAttributes = (obj: any) => {
	for (let key in obj) {
		if (obj[key] === null) {
			return true;
		}
	}
	return false;
}

export const getFullDate = (date: string): string => {
	const dateTS = new Date(date);
	return dateTS.toLocaleDateString();
};

export const ACCESS_TOKEN_COOKIE = 'heimdall.auth.AccessToken';

type FindCookieParams = {
	cookie: string | undefined;
	cookieName: typeof ACCESS_TOKEN_COOKIE;
};

export const findCookie = ({ cookie = "", cookieName }: FindCookieParams) => {
	const cookieRegex = new RegExp(`(?:^|; )${cookieName}=([^;]*)`);

	const match = cookie.match(cookieRegex);

	return match?.at(1) ?? null;
};
