import React, { FC, useRef, useState } from 'react';
import { SaveOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Col, Row, Form, Input, Select, Card } from 'antd';
import API, { BaseOrganizationLocation, Organization } from '../../services/adminApiService';
import { OrganizationLocation } from '../../services/adminApiService';
import { contriesLabelValues } from '../../config/countries';
import { useAppContext } from '../../contexts/app.context';
const LocationForm: FC<{
	organization: Organization;
	organizationLocation: OrganizationLocation;
	handleCancelLocationClick: () => void;
	callback: () => void;
}> = ({ organizationLocation, handleCancelLocationClick, callback, organization }) => {
	const { organizations = [] } = useAppContext();

	const [protocolCodes] = useState<{ label: string, value: string }[]>(organizations.filter(x => !!x.code).sort((a, b) => a.code?.localeCompare(b.code!)!).map((item, i) => ({ label: item.code!, value: item._id! })))
	const onFinish = async (values: OrganizationLocation) => {
		let response = null;
		if (values._id && values._id.length) {
			// update
			// const organizationId = organization._id!;
			const organizationId = organizations.find(o => o.code === values.organizationCode)!._id!;
			delete values.organizationCode;
			values.organizationId = organizationId;
			response = await API.UpdateLocation(values._id, values);
		} else {
			// create new
			const baseOrgLoc: BaseOrganizationLocation = {
				name: values.name as string,
				description: values.description ? values.description.toString().trim() : '',
				organizationId: values.organizationCode!,
				siteNumber: values.siteNumber,
				siteRegion: values.siteRegion ? values.siteRegion.toString().trim() : "",
				country: values.country ? values.country.toString().trim() : values.country,
				active: true,
				contentVersion: values.contentVersion
			};
			response = await API.CreateLocation(baseOrgLoc);
		}
		if (response) {
			callback();
		}
	};


	return (
		<Card style={{ marginBottom: 10 }} type="inner" title="Add new institution">
			<Form style={{ maxWidth: 800 }} autoComplete="off" aria-autocomplete="none" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} layout="horizontal" initialValues={{ ...organizationLocation }} onFinish={onFinish}>
				<Form.Item label="Id" name="_id" hidden={true}>
					<Input />
				</Form.Item>
				<Form.Item label="Protocol11" name="organizationCode" rules={[{ required: true, message: 'Please select protocol' }]}>
					<Select
						placement={"bottomLeft"}
						options={protocolCodes}
						showSearch={true}
						filterOption={(input, option: any) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
					/>
				</Form.Item>
				<Form.Item label="Institution" name="name" rules={[{ required: true, message: 'Please input name' }]}>
					<Input aria-autocomplete="none" />
				</Form.Item>
				<Form.Item label="Site number" name="siteNumber" rules={[{ required: true, message: 'Please input site number, if no number, set to 0' }]}>
					<Input type="number" autoComplete="off" />
				</Form.Item>
				<Form.Item label="Country" name="country" auto-complete="off" aria-autocomplete="none" rules={[{ required: true, message: 'Please select a country' }]}>
					<Select
						showSearch={true}
						filterOption={(input, option: any) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
						options={contriesLabelValues}
					/>
				</Form.Item>
				{/* {organization.organizationSettings?.contentVersioning &&
					<Form.Item label="Content Version" name="contentVersion">
						<Select
							defaultValue="V2"
							style={{ width: 120 }}
							options={organization!.contentVersions!.map(v => ({ value: v, label: v }))}
						/>
					</Form.Item>
				} */}
				<Form.Item>
					<Row>
						<Col className="action-col">
							<Button type="primary" htmlType="submit" >
								<SaveOutlined /> Save
							</Button>
						</Col>
						<Col>
							<Button type="default" onClick={handleCancelLocationClick}>
								<StopOutlined /> Cancel
							</Button>
						</Col>
					</Row>
				</Form.Item>
			</Form>
		</Card>
	);
};
export default LocationForm;
