export const Roles = {
	SUPER_ADMIN: 'SUPER_ADMIN',
	STUDY_ADMIN: 'STUDY_ADMIN',
	STUDY_COORDINATOR: 'STUDY_COORDINATOR',
} as const;

export type Role = typeof Roles[keyof typeof Roles];

/**
 * Map of `ROLES` to the role strings returned by the supergraph session,
 * in form of `<app-name>;<role-name>`
 */
export const AccountRoles = {
	SUPER_ADMIN: 'mypathfinder-admin;super-admin',
	STUDY_ADMIN: 'mypathfinder-admin;study-admin',
	STUDY_COORDINATOR: 'mypathfinder-admin;study-coordinator',
}

export const rolesList = [Roles.STUDY_ADMIN, Roles.STUDY_COORDINATOR, Roles.SUPER_ADMIN];
export const accountsRolesList = [Roles.STUDY_ADMIN, Roles.STUDY_COORDINATOR, Roles.SUPER_ADMIN];
