import React, { FC, useState, useEffect } from 'react';
import { UploadOutlined, ArrowLeftOutlined, QuestionCircleOutlined, WarningTwoTone } from '@ant-design/icons';
import { Button, Col, Form, Row, Table, Upload, UploadProps, message, Alert, Tag, Popover, Tooltip } from 'antd';
import { BulkLocationsResponse, Organization, OrganizationLocation } from '../../services/adminApiService';
import Page from '../../components/Page';
import Column from 'antd/lib/table/Column';
import API from '../../services/adminApiService';
import { parseCSV } from '../../services/csv-parser.service';
import { useAppContext } from '../../contexts/app.context';
import FeedbackFormService from '../../services/feedbackFormService';
import { Link } from 'react-router-dom';
import { routeNames } from '../../routes/routes';
import { CsvData } from "./Locations";
import { arrayToObject } from '../../utils';

const newPrefix = "new_";

const csvHelpContent = (
	<div>
		<p>Download the CSV from the list on the previous page, and edit the CSV.</p>
		<h3>Columns</h3>
		<p>
			<b>internalId:</b> Id for making sure updates are done for the right record, keep the value for updates. For new institution, leave blank.<br />
			<b>protocol:</b>* The code to assign the location to. Needs to be valid for upload, such as COMP005, COMP006, COMP401 etc.<br />
			<b>siteName:</b>* Name of the institution<br />
			<b>siteId:</b>* Site number, if you dont know at this time, set to 0 (zero)<br />
			<b>country:</b>* Country of the institution<br />
		</p>
		<p>For <b>new institutions</b>, add a new row, either top or bottom and fill out the information. Leave the first column blank.</p>
		<p>You can safely remove the rows you don't touch, they will not be deleted!</p>
		<p>Once you upload the csv, only the changed rows will show up and the updated values will be highlighted in bold.<br />
			Duplicates will be removed automatically and only one row will show.</p>
		<p>Press save at the bottom of the screen to save the changes into the system.</p>
		<p>You cannot remove any data with the CSV upload.</p>
	</div>
);

const actions = (uploadSettings: UploadProps) => (
	<Row gutter={8}>
		<Col>
			<Popover placement="bottomLeft" title={"CSV Upload Information"} content={csvHelpContent} trigger="click">
				<Button icon={<QuestionCircleOutlined />}>CSV Help</Button>
			</Popover>
		</Col>
		<Col>
			<Upload {...uploadSettings}>
				<Button type="primary" icon={<UploadOutlined />}>
					Upload CSV
				</Button>
			</Upload>
		</Col>
	</Row>
);

const ActionColumn = (value: any, record: CsvData) => {
	if (record.error) {
		return <Tooltip placement="right" title={record.msg}><Tag color={"red"}> Error</Tag></Tooltip>
	}
	return !!record.internalId && !record.internalId.startsWith(newPrefix) ? <Tag color={"blue"}>Update</Tag> : <Tag color={"green"}>Add</Tag>;
}


/**
 * Returns a hash code from a string
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
function hashCode(str: string = "") {
	let hash = 0;
	for (let i = 0, len = str.length; i < len; i++) {
		let chr = str.charCodeAt(i);
		hash = (hash << 5) - hash + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
}

const toUniqueString = (name: string = "", protocol: string = "", siteNumber: string = "") => `${name}${protocol}${siteNumber}`;

const arrayToObjectHash = (array: OrganizationLocation[], key: string) =>
	array.reduce((acc, curr) => {
		acc[curr[key]] = {
			hash: hashCode(toUniqueString(curr.name.trim(), curr.organizationCode || "", `${curr.siteNumber}`)),
			location: curr,
		};
		// add site number to list, to be able to ensure uniqueness across site ids'
		acc[curr.siteNumber] = {
			hash: hashCode(toUniqueString(curr.name.trim(), curr.organizationCode || "", `${curr.siteNumber}`)),
			location: curr,
		};
		return acc;
	}, {} as any);



interface LocationHashMap { [key: string]: { hash: number, location: OrganizationLocation } };
interface OrganizationMap { [key: string]: Organization };

const findDiffLocations = (hashedLocs: LocationHashMap, csvLocations: CsvData[]) => {
	// object with hash as key
	const hashObj: any = {};
	Object.keys(hashedLocs).forEach(x => {
		hashObj[`${hashedLocs[x].hash}`] = true;
	});

	const changedLocations = csvLocations.filter(x => {
		const hash = hashCode(toUniqueString(x.siteName.trim(), x.protocol.trim() || "", `${x.siteId}`));
		// filter our duplicates
		if (hashObj[`${hash}`]) {
			return false;
		}
		// add hash to list ro make sure we don't add duplicates for new items
		hashObj[`${hash}`] = true;
		return true;
	})
	return changedLocations;
}

const BulkLocations: FC = () => {
	const { locations, organizations, getLocations } = useAppContext();
	FeedbackFormService.clear();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [csvRows, setCSVRows] = useState<CsvData[] | undefined>(undefined);
	const [apiResponse, setApiResponse] = useState<BulkLocationsResponse>();
	const [errorCsvRows, setErrorCSVRows] = useState<CsvData[] | undefined>();
	const [locationsMapped, setLocationsMapped] = useState<LocationHashMap>({});
	const [organizationsMapped, setOrganizationsMapped] = useState<OrganizationMap>({});

	useEffect(() => {
		if (locations) {
			const locMap = arrayToObjectHash(locations, "_id");
			setLocationsMapped(locMap);
		}
		if (organizations) {
			const orgMap = arrayToObject(organizations, "code");
			setOrganizationsMapped(orgMap);
		}
	}, [locations, organizations]);


	const identifyErrorsInCsvRow = (csvRow: CsvData, index: number) => {
		const organizationId = organizationsMapped[csvRow.protocol] ? organizationsMapped[csvRow.protocol]._id : undefined;
		if (!csvRow.internalId && locationsMapped[csvRow.siteId]) {
			csvRow.msg = `Trying to insert new but site number already in use ${csvRow.siteId}. If you meant to update, export the list again with internalId or update it manually.`;
			csvRow.error = true;
		}
		csvRow.internalId = csvRow.internalId || `${newPrefix}${index}`;
		if (!organizationId) {
			csvRow.error = true;
			csvRow.msg = `Cannot map code ${csvRow.protocol} to protocol, make sure it exists.`;
		} else {
			csvRow.organizationId = organizationId;
		}
		if (!csvRow.siteName || csvRow.siteName === "") {
			csvRow.error = true;
			csvRow.msg = `Institution (siteName) cannot be empty.`;
		}
		if (!csvRow.country || csvRow.country === "") {
			csvRow.error = true;
			csvRow.msg = `Country cannot be empty.`;
		}

		return csvRow;
	}

	const identifyErrorsInCsvList = (csvRows: CsvData[]) => csvRows.map((row, i) => identifyErrorsInCsvRow(row, i));

	const uploadProps: UploadProps = {
		multiple: false,
		maxCount: 1,
		beforeUpload: (file) => {
			const isValidFile = file.type === 'text/csv';
			if (!isValidFile) {
				message.error(`${file.name} is not a valid CSV file`);
				return Upload.LIST_IGNORE;
			}
			// not uploading ref: https://ant.design/components/upload
			return false;
		},
		onChange: async (info) => {
			if (info.file.status === 'removed') {
				setCSVRows(undefined);
				return;
			}
			const m: CsvData[] = await parseCSV(info.file, "locations");
			const uniqueLocations = findDiffLocations(locationsMapped, m);
			// indentify if theres any errors
			const processedRows = identifyErrorsInCsvList(uniqueLocations);
			setCSVRows(processedRows);
			setApiResponse(undefined);
		},
	};

	const handleBulkLocations = async () => {
		if (!Array.isArray(csvRows) || !csvRows) {
			return;
		}
		const locations: OrganizationLocation[] = [];
		const errorData: CsvData[] = [];
		csvRows.forEach((row, i) => {
			row = identifyErrorsInCsvRow(row, i);
			if (row.error) {
				errorData.push(row);
			} else {
				const loc: OrganizationLocation = {
					_id: row.internalId.startsWith(newPrefix) ? undefined : row.internalId.trim(),
					country: row.country.trim(),
					name: row.siteName.trim(),
					siteNumber: +row.siteId,
					organizationId: row.organizationId!,
					active: true,
					siteRegion: "",
				};
				locations.push(loc);
			}
		});

		setIsLoading(true);
		const res = await API.BulkLocations(locations);
		await getLocations();
		if (res) {
			setApiResponse(res);
			if (errorData.length) {
				setErrorCSVRows(errorData);
				setCSVRows(errorData);
			} else {
				setErrorCSVRows([]);
				setCSVRows([]);
			}
		}

		setIsLoading(false);
	};

	const csvFooter = () => {
		if (!csvRows || !csvRows.length) {
			return;
		}
		return (
			<Form name="bulk" layout="inline" onFinish={handleBulkLocations}>
				<Form.Item>
					<Button type="primary" htmlType="submit">
						Save
					</Button>
				</Form.Item>
				<Form.Item>
					<Button type="default" htmlType="reset">
						Cancel
					</Button>
				</Form.Item>
			</Form>
		);
	};

	const ColumnDiff = (key: string) => (value: any, record: CsvData, index: number) => {
		if (record.internalId) {
			const orgValueObj = locationsMapped[record.internalId];
			if (orgValueObj && orgValueObj.location) {
				if (orgValueObj.location[key] === undefined && value === "") {
					return value;
				}
				if (`${orgValueObj.location[key]}` !== `${value}`) {
					return <b>{value}</b>
				}
			}
		}
		if (!value || value === "") {
			switch (key) {
				case "organizationCode":
				case "name":
				case "country":
					return <><WarningTwoTone twoToneColor="red" /></>
			}
		}

		return value;
	};

	const UploadError = () => <div style={{ padding: 10 }}><Alert message={`Some institutions was not uploaded, the ones with errors are displayed in the list. Inserted ${apiResponse?.inserted} Updated:${apiResponse?.updated}`} type="error" /></div>;
	const SaveWarning = () => <div style={{ padding: 10 }}><Alert message="Institutions not saved yet. Only rows with changes are showing in the list, make sure you click 'Save' at the bottom of the page, if everything looks correct." type="warning" /></div>;
	const UploadSuccess = () => <div style={{ padding: 10 }}><Alert message={`Institutions was uploaded successfully. Inserted ${apiResponse?.inserted}. Updated:${apiResponse?.updated}.`} type="success" /></div>;
	const renderTable = () => {
		return (
			<>
				{errorCsvRows && errorCsvRows.length > 0 ? <UploadError /> : null}
				{csvRows && !apiResponse ? <SaveWarning /> : null}
				{apiResponse && (apiResponse.inserted || apiResponse?.updated) ? <UploadSuccess /> : null}
				<Table
					pagination={{
						pageSize: 100,
					}}
					rowKey="internalId" dataSource={csvRows} footer={csvFooter} loading={isLoading} locale={{ emptyText: (<div>Click the 'Upload CSV' button in the top right corner</div>) }}>
					<Column title="Action" key="internalId" dataIndex="internalId" render={ActionColumn} />
					<Column title="Institution" key="institution" dataIndex="siteName" render={ColumnDiff("name")} />
					<Column title="Institution Number" key="siteId" dataIndex="siteId" render={ColumnDiff("siteNumber")} />
					<Column title="Protocol" key="protocol" dataIndex="protocol" render={ColumnDiff("organizationCode")} />
					<Column title="Country" key="country" dataIndex="country" render={ColumnDiff("country")} />
				</Table>
			</>
		);
	};

	return (
		<Page title="Institutions bulk upload" actions={actions(uploadProps)} description="Upload multiple institutions from a .csv file. Use the same format as downloaded from the list.">
			<span style={{ paddingBottom: 10, marginTop: -20 }}><Link to={routeNames.locationsRoute.path}><ArrowLeftOutlined /> Back to list</Link></span>
			<div>{renderTable()}</div>
		</Page>
	);
};

export default BulkLocations;
