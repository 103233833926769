import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Popconfirm, Space, Table, Drawer, Tooltip, Input } from 'antd';
import { isEmpty } from 'lodash';
import { Organization, OrganizationLocation } from '../../services/adminApiService';
import API from '../../services/adminApiService';
import LocationForm from './LocationForm';
import { PlusOutlined, DownloadOutlined, UploadOutlined, DeleteOutlined, EditTwoTone } from '@ant-design/icons';
import { CSVLink } from "react-csv";
import { ColumnsType } from 'antd/lib/table';
import Page from '../../components/Page';
import { Link } from 'react-router-dom';
import { routeNames } from '../../routes/routes';
import { useAppContext } from '../../contexts/app.context';
import { checkForNullAttributes, getFullDate, sorter, sortInt } from '../../utils';
import { FilterValue } from 'antd/lib/table/interface';

export interface CsvData {
	internalId: string;
	protocol: string;
	siteName: string;
	siteId: number;
	country: string;
	msg?: string;
	error?: boolean;
	organizationId?: string;
}

const formatToCsv = (data: OrganizationLocation[]): CsvData[] => {
	const csvData = data.map(x => ({
		internalId: x._id!,
		protocol: x.organizationCode!,
		siteName: x.name,
		siteId: x.siteNumber,
		country: x.country,
	}));
	return csvData;
}

const Locations: FC<{ organizationId: string }> = ({ organizationId }) => {
	const { locations, getLocations, organizations = [] } = useAppContext();
	const [organizationLocationsDisplay, setOrganizationLocationsDisplay] = useState<OrganizationLocation[]>([]);
	const [protocolCodes, setProtocolCodes] = useState<{ label: string, value: string }[]>([]);
	const [organization, setOrganization] = useState<Organization>();
	const [csvData, setCsvData] = useState<CsvData[]>([]);
	const [edit, setEdit] = useState(false);
	const [add, setAdd] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedOrganizationLocation, setSelectedOrganizationLocation] = useState<OrganizationLocation>({} as OrganizationLocation);
	const [pageSize, setPageSize] = useState(50);

	const apiReq = useCallback(async () => {
		if (locations) {
			if (searchTerm.length) {
				handleSearch(searchTerm);
			} else {
				setOrganizationLocationsDisplay(locations || []);
			}
		}
		setOrganization(organizations.find(o => o._id === organizationId));
		setSelectedOrganizationLocation({} as OrganizationLocation);
		setEdit(false);
		setAdd(false);
	}, [locations]);

	useEffect(() => {
		apiReq();
	}, [apiReq]);

	// set the protocol codes from the organizations
	useEffect(() => {
		if (organizations.length) {
			const codes = organizations.filter(x => !!x.code).sort((a, b) => a.code?.localeCompare(b.code!)!).map((item, i) => ({ label: item.code!, value: item._id! }));
			setProtocolCodes(codes);
		}
	}, [organizations]);

	const onClose = () => {
		setEdit(false);
	};

	const columns: ColumnsType<OrganizationLocation> = [
		{
			title: 'Protocol', width: 150, dataIndex: 'organizationCode', key: 'organizationCode', sorter: (a, b) => sorter(a, b, 'organizationCode'),
			filters: protocolCodes.map(o => ({ text: o.label, value: o.label })),
			onFilter: (value: any, record) => !isEmpty(record.organizationCode) ? record.organizationCode!.indexOf(value) > -1 : false,
		},
		{ title: 'Institution', dataIndex: 'name', key: 'name', sorter: (a, b) => sorter(a, b, 'name') },
		{ title: 'Country', dataIndex: 'country', key: 'country', sorter: (a, b) => sorter(a, b, 'country'), width: 150 },
		{ title: 'Site#', dataIndex: 'siteNumber', key: 'siteNumber', sorter: (a, b) => sortInt(a, b, 'siteNumber'), width: 130 },
		{ title: 'Updated', dataIndex: 'updatedAt', key: 'updatedAt', sorter: (a, b) => sortInt(a, b, 'updatedAt'), render: ((date: string) => getFullDate(date)), width: 130 },
		{
			title: 'Action',
			key: 'action',
			width: 120,
			render: (record: any) => {
				return (
					<Space size="middle">
						<Tooltip placement="left" title={`Edit institution`}>
							<Button type="default" icon={<EditTwoTone />} onClick={() => locationEditHandler(record)} />
						</Tooltip>
						<Popconfirm placement="topLeft" title={`Are you sure want to remove this location?`} onConfirm={() => removeLocation(record)} okText="Yes" cancelText="No">
							<Tooltip placement="left" title={`Remove institution`}>
								<Button type="default" danger={true} icon={<DeleteOutlined />} />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	const formCallback = async () => await getLocations();

	const locationEditHandler = (data?: any) => {
		setSelectedOrganizationLocation(data);
		setEdit(true);
	};

	const handleCancelLocationClick = () => {
		setSelectedOrganizationLocation({} as OrganizationLocation);
		setEdit(false);
		setAdd(false);
	};

	const removeLocation = async (data: any) => {
		await API.DeleteLocation(data._id);
		await getLocations();
	};

	const handleAddLocation = () => {
		setAdd(true);
	};

	const handleSearch = (e: any) => {
		const value = e.target ? e.target.value.toLowerCase() : e;
		setSearchTerm(value);
		if (value === "") {
			setOrganizationLocationsDisplay(locations!);
			return;
		}
		if (value.length < 3 || !locations) {
			return;
		}
		// perform heavy search in the list, might have to be revisited if the list grows too long
		const filteredResults = locations.filter(x =>
			(x.organizationName && x.organizationName.toLowerCase().includes(value)) ||
			(x.country && x.country.toLowerCase().includes(value)) ||
			(x.organizationCode && x.organizationCode!.toLowerCase().includes(value)) ||
			(x.name && x.name.toLowerCase().includes(value)) ||
			(x.siteNumber !== undefined && x.siteNumber.toString().includes(value))
		);
		setOrganizationLocationsDisplay(filteredResults);
	}

	const onTableChante = (pagination: any, filters: Record<string, FilterValue | null>, sorter: any, extra: { currentDataSource: any, action: any }) => {
		console.log(`has filters`, filters);
		// has filter
		const hasFilters = !checkForNullAttributes(filters);
		if (hasFilters) {
			console.log(`has filters`);

			setOrganizationLocationsDisplay(extra.currentDataSource);
		} else {
			handleSearch(searchTerm)
		}
	}

	return (
		<Page title={`Institutions`} description="Manage institutions across protocols" actions={
			<>
				<CSVLink data={csvData} filename="institutions-admin.csv" onClick={() => { setCsvData(formatToCsv(organizationLocationsDisplay || [])) }}>
					<Button type="default" >
						<DownloadOutlined /> Download CSV
					</Button>
				</CSVLink>
				&nbsp;&nbsp;&nbsp;
				<Link to={routeNames.bulkLocationsRoute.path}><Button type="default" ><UploadOutlined /> Upload Bulk</Button></Link>
				&nbsp;&nbsp;&nbsp;
				{!add && (
					<Button type="primary" onClick={handleAddLocation}>
						<PlusOutlined /> Add Institution
					</Button>
				)}
			</>
		}>
			{!add && (
				<>
					<Input.Search value={searchTerm} allowClear style={{ width: 300, paddingBottom: 20 }} placeholder="Search the list, min. 3 characters" defaultValue="" onSearch={handleSearch} onChange={handleSearch} />
				</>
			)}
			{add && (
				<LocationForm
					handleCancelLocationClick={handleCancelLocationClick}
					organizationLocation={selectedOrganizationLocation}
					organization={organization!}
					callback={formCallback}
				/>
			)}

			<Table
				pagination={{
					pageSize: pageSize,
					onShowSizeChange: (_, pageSize) => {
						setPageSize(pageSize);
					},
				}}
				columns={columns} dataSource={organizationLocationsDisplay} rowKey="_id"
				onChange={onTableChante}
			></Table>
			<Drawer title="Institution Details" placement="right" closable={true} onClose={onClose} open={edit} width={500}>
				<b>Update institution</b>
				{selectedOrganizationLocation._id ? <LocationForm
					organization={organization!}
					handleCancelLocationClick={handleCancelLocationClick}
					organizationLocation={selectedOrganizationLocation}
					callback={formCallback}
				/> : null}

			</Drawer>
		</Page>
	);
};
export default Locations;
