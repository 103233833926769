// Auto generated from Swagger Codegen
import { BaseService } from './baseService';
const baseUrl = window.__RUNTIME_CONFIG__.REACT_APP_USE_LOCAL_API === 'true' ? window.__RUNTIME_CONFIG__.REACT_APP_LOCAL_BASE_URL : '/admin-service';

export type BaseUser = {
	email: string;
	phone?: string;
	firstName?: string;
	lastName?: string;
	formattedPhone?: string;
	locationIds: Array<string>;
	organizationIds: Array<string> | undefined;
	active?: boolean;
	role?: string;
	profile?: string;
};

export type BaseModel = {
	_id: string;
	active?: boolean;
	createdAt?: string;
	createdBy?: string;
	updatedAt?: string;
	updatedBy?: string;
	deleted?: boolean;
};

export type AdminUser = {
	email?: string;
	phone?: string;
	formattedPhone?: string;
	locationIds?: Array<string>;
	organizationIds?: Array<string>;
	firstName?: string;
	lastName?: string;
	active?: boolean;
	role?: string;
	_id?: string;
	createdAt?: string;
	createdBy?: string;
	updatedAt?: string;
	updatedBy?: string;
	deleted?: boolean;
	profile?: string;
} & {
	[key: string]: any;
};

export type ValidateErrorJSON = {
	message: 'Validation failed';
	details: {} & {
		[key: string]: void;
	};
};

export type BaseOrganizationLocation = {
	_id?: string;
	organizationId: string;
	name: string;
	description?: string;
	firstName?: string;
	lastName?: string;
	active: boolean;
	country: string;
	siteNumber: number;
	siteRegion?: string;
	email?: string;
	contentVersion?: string;
};

export type FeatureSettings = {} & {
	[key: string]: {} & {
		[key: string]: any;
	};
};

export type BaseSettings = {
	features?: FeatureSettings;
	settings?: FeatureSettings;
};

export interface OrganizationLocation extends BaseOrganizationLocation {
	createdAt?: string;
	createdBy?: string;
	updatedAt?: string;
	updatedBy?: string;
	deleted?: boolean;
	features?: FeatureSettings;
	settings?: FeatureSettings;
	organizationName?: string;
	organizationCode?: string;
	[key: string]: any;
}

export type BaseOrganization = {
	name: string;
	description?: string;
	locationIds?: Array<string>;
	protocolId?: number;
	externalStudyId?: string;
	externalStudyCRO?: string;
	code?: string;
	about?: string;
};

export type OrganizationSettings = {
	organizationSettings?: {
		displayUserEmail: boolean;
		displayUserName: boolean;
		oneTimeCodeEnabled: boolean;
		contentVersioning: boolean;
		lock?: boolean;
		displayDosingDate: boolean;
	} & {
		[key: string]: any;
	};
};

export type Organization = {
	name?: string;
	description?: string;
	locationIds?: Array<string>;
	protocolId?: number;
	externalStudyId?: string;
	externalStudyCRO?: string;
	code?: string;
	about?: string;
	_id?: string;
	active?: boolean;
	createdAt?: string;
	createdBy?: string;
	updatedAt?: string;
	updatedBy?: string;
	deleted?: boolean;
	features?: FeatureSettings;
	settings?: FeatureSettings;
	contentVersions?: string[];
	organizationSettings?: {
		displayUserEmail: boolean;
		displayUserName: boolean;
		oneTimeCodeEnabled: boolean;
		contentVersioning: boolean;
		lock?: boolean;
		displayDosingDate: boolean;
	} & {
		[key: string]: any;
	};
} & {
	[key: string]: any;
};

export type ConfigPayload = {
	features?: FeatureSettings;
	settings?: FeatureSettings;
	organizationSettings?: {
		displayUserEmail: boolean;
		displayUserName: boolean;
		oneTimeCodeEnabled: boolean;
		lock?: boolean;
		contentVersioning: boolean;
		displayDosingDate: boolean;
	} & {
		[key: string]: any;
	};
} & {
	[key: string]: any;
};

export type Fields = {} & {
	[key: string]: string;
};

export type PresignedPost = {
	url: string;
	fields: Fields;
};

export type DOCUMENT_TYPE = 'privacyPolicy' | 'termsOfService' | 'other';

export type FileToUpload = {
	filename: string;
	type: DOCUMENT_TYPE;
};

export type CompassDocumentBase = {
	_id?: string;
	parentId?: string;
	title: string;
	documentType: DOCUMENT_TYPE;
	version: number;
	language: string;
	uri: string;
	description?: string;
	organizationId?: string;
	locationId?: string;
	createdByName?: string;
};

export type CompassDocument = {
	_id?: string;
	parentId?: string;
	title?: string;
	documentType?: DOCUMENT_TYPE;
	version?: number;
	language?: string;
	uri?: string;
	description?: string;
	organizationId?: string;
	locationId?: string;
	createdByName?: string;
	active?: boolean;
	createdAt?: string;
	createdBy?: string;
	updatedAt?: string;
	updatedBy?: string;
	deleted?: boolean;
} & {
	[key: string]: any;
};

export type UserBase = {
	locationId: string;
	locationName?: string;
	organizationId: string;
	organizationName?: string;
	dosingDate?: Date;
	startDate?: string;
	endDate?: string;
	sub?: string;
	email?: string;
	firstName?: string;
	lastName?: string;
	onboarding?: boolean;
	deleteRequest?: boolean;
	testUser?: boolean;
};

export type User = {
	locationId?: string;
	locationName?: string;
	organizationId?: string;
	organizationName?: string;
	startDate?: string;
	endDate?: string;
	sub?: string;
	email?: string;
	firstName?: string;
	lastName?: string;
	onboarding?: boolean;
	deleteRequest?: boolean;
	_id?: string;
	active?: boolean;
	dosingDate?: Date;
	createdAt?: string;
	createdBy?: string;
	updatedAt?: string;
	updatedBy?: string;
	deleted?: boolean;
	features?: FeatureSettings;
	settings?: FeatureSettings;
	testUser?: boolean;
	contentVersion?: string;
} & {
	[key: string]: any;
};

export interface BulkUser {
	email: string;
	firstName: string;
	lastName: string;
	active: boolean;
	role: string;
	organizationIds: string[];
	site_number: string;
	institution?: string;
	country?: string;
	site_region?: string;
}

export type LoginCode = {
	_id: string;
	active?: boolean;
	createdAt?: string;
	createdBy?: string;
	updatedAt?: string;
	updatedBy?: string;
	deleted?: boolean;
	userId: string;
	used: boolean;
};

export type UserLoginCode = {
	user: User;
	loginCode: LoginCode;
};

export type Success = {
	success: boolean;
	message: string;
	id: string;
};

export type UserInfoAdminPayload = {
	active?: boolean;
	dosingDate?: Date | null;
	deleteRequest?: boolean;
	locationId?: string;
	locationName?: string;
	testUser?: boolean;
	contentVersion?: string;
};

export type UserInfoBase = {
	user: User;
};

export type UserInfoSettings = {
	user?: User;
	features?: FeatureSettings;
	settings?: FeatureSettings;
} & {
	[key: string]: any;
};


export type UserInfoPayload = {
	onboarding?: boolean;
	deleteRequest?: boolean;
};

export interface Part {
	value: string;
	label: string;
}

export interface Visit {
	visit_type: string;
	visit_number: string;
	visit_label: string;
	blinded: boolean;
	part: Part;
}

export type BulkLocationsResponse = { inserted: number, updated: number };
/**
 *
 * @class AdminApi
 */
class AdminApi extends BaseService {
	/**
	 * Get a adminUsers
	 * @method
	 * @name AdminApi#GetAdminUsers
	 */
	GetAdminUsers(): Promise<AdminUser[]> {
		return this.request('GET', `${baseUrl}/admin/adminUsers`);
	}

	/**
	 * Get a adminUser by the adminUser id
	 * @method
	 * @name AdminApi#GetAdminUser
	 * @param {string} adminUserId -
	 */
	GetAdminUser(adminUserId: string): Promise<AdminUser> {
		return this.request('GET', `${baseUrl}/admin/adminUsers/${adminUserId}`);
	}
	/**
	 * Get a adminUser by the adminUser id
	 * @method
	 * @name AdminApi#GetAdminUser
	 * @param {string} adminUserId -
	 */
	GetCurrentAdminUser(): Promise<AdminUser> {
		return this.request('GET', `${baseUrl}/admin/adminUsers/current/userInfo`);
	}

	AddBulkAdminUsers(users: BulkUser[]): Promise<AdminUser[]> {
		return this.request('POST', `${baseUrl}/admin/adminUsers/bulk`, users);
	}
	/**
	 * Update adminUser information
	 * @method
	 * @name AdminApi#UpdateAdminUser
	 * @param {string} adminUserId -
	 * @param {} requestBody -
	 */
	UpdateAdminUser(adminUserId: string, requestBody: AdminUser): Promise<AdminUser> {
		return this.request('PUT', `${baseUrl}/admin/adminUsers/${adminUserId}`, requestBody);
	}
	/**
	 * Deletes a adminUser (soft delete)
	 * @method
	 * @name AdminApi#DeleteAdminUser
	 * @param {string} adminUserId -
	 */
	DeleteAdminUser(adminUserId: string): Promise<void> {
		return this.request('DELETE', `${baseUrl}/admin/adminUsers/${adminUserId}`);
	}
	/**
	 * Gets adminUsers by organization id
	 * @method
	 * @name AdminApi#GetAdminUsersByOrganization
	 * @param {string} organizationId -
	 */
	GetAdminUsersByOrganization(organizationId: string): Promise<void> {
		return this.request('GET', `${baseUrl}/admin/adminUsers/organization/${organizationId}`);
	}

	/**
	 * Gets adminUsers by organization id
	 * @method
	 * @name AdminApi#GetUsersByRole
	 * @param {string} role -
	 */
	GetUsersByRole(role: string): Promise<AdminUser[]> {
		return this.request('GET', `${baseUrl}/admin/adminUsers/role/${role}`);
	}
	/**
	 * Get adminUsers by organization and location id
	 * @method
	 * @name AdminApi#GetAdminUsersByOrganizationAndLocations
	 * @param {string} organizationId -
	 * @param {string} locationId -
	 */
	GetAdminUsersByOrganizationAndLocations(organizationId: string, locationId: string): Promise<void> {
		return this.request('GET', `${baseUrl}/admin/adminUsers/organization/${organizationId}/location/${locationId}`);
	}
	/**
	 * Create a new adminUser
	 * @method
	 * @name AdminApi#CreateAdminUser
	 * @param {} requestBody -
	 */
	CreateAdminUser(requestBody: BaseUser): Promise<AdminUser> {
		return this.request('POST', `${baseUrl}/admin/adminUsers`, requestBody);
	}
	/**
	* Remove all adminUsers
TODO: ONLY ADMINS CAN DO THIS AND NOT IN PRODUCTION, NEEDS TO BE IMPLEMENTED
	* @method
	* @name AdminApi#DeleteAdminUsers
	*/
	DeleteAdminUsers(): Promise<void> {
		return this.request('DELETE', `${baseUrl}/admin/adminUsers/adminUsers`);
	}
	/**
	 * List of locations
	 * @method
	 * @name AdminApi#GetLocationsByOrganization
	 * @param {string} organizationId -
	 */
	GetLocationsByOrganization(organizationId: string): Promise<OrganizationLocation[]> {
		return this.request('GET', `${baseUrl}/admin/locations/organization/${organizationId}`);
	}
	/**
	 * Get a location
	 * @method
	 * @name AdminApi#GetLocation
	 * @param {string} locationId -
	 */
	GetLocation(locationId: string): Promise<OrganizationLocation> {
		return this.request('GET', `${baseUrl}/admin/locations/${locationId}`);
	}
	/**
	 * Update a location
	 * @method
	 * @name AdminApi#UpdateLocation
	 * @param {string} locationId -
	 * @param {} requestBody -
	 */
	UpdateLocation(locationId: string, requestBody: OrganizationLocation): Promise<OrganizationLocation> {
		return this.request('PUT', `${baseUrl}/admin/locations/${locationId}`, requestBody);
	}
	/**
	 * Delete a location - soft delete
	 * @method
	 * @name AdminApi#DeleteLocation
	 * @param {string} locationId -
	 */
	DeleteLocation(locationId: string): Promise<void> {
		return this.request('DELETE', `${baseUrl}/admin/locations/${locationId}`);
	}
	/**
	 * Add a new location
	 * @method
	 * @name AdminApi#CreateLocation
	 * @param {} requestBody -
	 */
	CreateLocation(requestBody: BaseOrganizationLocation): Promise<OrganizationLocation> {
		return this.request('POST', `${baseUrl}/admin/locations`, requestBody);
	}
	/**
	 * Bulk add locations
	 * @name AdminApi#BulkLocations
	 * @param {} requestBody -
	 */
	BulkLocations(requestBody: BaseOrganizationLocation[]): Promise<BulkLocationsResponse> {
		return this.request('POST', `${baseUrl}/admin/locations/bulk`, requestBody);
	}
	/**
	 * List of Locations
	 * @method
	 * @name AdminApi#GetLocations
	 */
	GetLocations(): Promise<OrganizationLocation[]> {
		return this.request('GET', `${baseUrl}/admin/locations`);
	}
	/**
	 * List of organizations
	 * @method
	 * @name AdminApi#GetOrganizations
	 */
	GetOrganizations(): Promise<Organization[]> {
		return this.request('GET', `${baseUrl}/admin/organizations`);
	}
	/**
	 * Add a new organization
	 * @method
	 * @name AdminApi#CreateOrganization
	 * @param {} requestBody -
	 */
	CreateOrganization(requestBody: BaseOrganization): Promise<Organization> {
		return this.request('POST', `${baseUrl}/admin/organizations`, requestBody);
	}
	/**
	 * Get a organization
	 * @method
	 * @name AdminApi#GetOrganization
	 * @param {string} organizationId -
	 */
	GetOrganization(organizationId: string): Promise<Organization> {
		return this.request('GET', `${baseUrl}/admin/organizations/${organizationId}`);
	}

	/**
	 * Update an organization name
	 * @method
	 * @name AdminApi#UpdateOrganization
	 * @param {string} organizationId -
	 * @param {} requestBody -
	 */
	UpdateOrganization(organizationId: string, requestBody: BaseOrganization): Promise<Organization> {
		return this.request('PUT', `${baseUrl}/admin/organizations/${organizationId}`, requestBody);
	}
	/**
	 * Delete a organization - soft delete
	 * @method
	 * @name AdminApi#DeleteOrganization
	 * @param {string} organizationId -
	 */
	DeleteOrganization(organizationId: string): Promise<void> {
		return this.request('DELETE', `${baseUrl}/admin/organizations/${organizationId}`);
	}
	/**
	 * Update an organization settings
	 * @method
	 * @name AdminApi#UpdateOrganizationSettings
	 * @param {string} organizationId -
	 * @param {} requestBody -
	 */
	UpdateOrganizationSettings(organizationId: string, requestBody: ConfigPayload): Promise<Organization> {
		return this.request('PUT', `${baseUrl}/admin/organizations/${organizationId}/settings`, requestBody);
	}
	/**
	 * Returns the user id by looking up a code
	 * @method
	 * @name AdminApi#GetDefaultSettings
	 */
	GetDefaultSettings(): Promise<BaseSettings> {
		return this.request('GET', `${baseUrl}/settings/defaults`);
	}
	/**
	 * Create new presigned url
	 * @method
	 * @name AdminApi#GetPresignedPostUrl
	 * @param {} requestBody -
	 */
	GetPresignedPostUrl(requestBody: FileToUpload): Promise<PresignedPost> {
		return this.request('POST', `${baseUrl}/admin/storage/documents/pre-signed-url`, requestBody);
	}
	/**
	 * Create new uploaded document
	 * @method
	 * @name AdminApi#AddDocument
	 * @param {} requestBody -
	 */
	AddDocument(requestBody: CompassDocumentBase): Promise<CompassDocument> {
		return this.request('POST', `${baseUrl}/admin/storage/documents`, requestBody);
	}
	/**
	 * List documents
	 * @method
	 * @name AdminApi#GetDocuments
	 */
	GetDocuments(): Promise<void> {
		return this.request('GET', `${baseUrl}/admin/storage/documents`);
	}
	/**
	 * Update a document
	 * @method
	 * @name AdminApi#UpdateDocument
	 * @param {string} documentId -
	 * @param {} requestBody -
	 */
	UpdateDocument(documentId: string, requestBody: CompassDocument): Promise<CompassDocument> {
		return this.request('PUT', `${baseUrl}/admin/storage/documents/${documentId}`, requestBody);
	}
	/**
	 * List documents
	 * @method
	 * @name AdminApi#GetDocumentById
	 * @param {string} documentId -
	 */
	GetDocumentById(documentId: string): Promise<CompassDocument> {
		return this.request('GET', `${baseUrl}/admin/storage/documents/${documentId}`);
	}
	/**
	 * Get a user by the user id
	 * @method
	 * @name AdminApi#GetUser
	 * @param {string} userId -
	 */
	GetUser(userId: string): Promise<User> {
		return this.request('GET', `${baseUrl}/admin/users/${userId}`);
	}

	/**
	 * Get a user by phone
	 * @method
	 * @name AdminApi#GetUser
	 * @param {string} phone -
	 */
	GetUserByPhone(phone: string): Promise<User> {
		return this.request('GET', `/public-admin/phone/${phone}`);
	}
	/**
	 * Update user information
	 * @method
	 * @name AdminApi#UpdateUser
	 * @param {string} userId -
	 * @param {} requestBody -
	 */
	UpdateUser(userId: string, requestBody: UserInfoAdminPayload): Promise<User> {
		return this.request('PUT', `${baseUrl}/admin/users/${userId}`, requestBody);
	}
	/**
	 * Deletes a user (soft delete)
	 * @method
	 * @name AdminApi#DeleteUser
	 * @param {string} userId -
	 */
	DeleteUser(userId: string): Promise<void> {
		return this.request('DELETE', `${baseUrl}/admin/users/${userId}`);
	}
	/**
	 *
	 * @method
	 * @name AdminApi#GetLoginCode
	 * @param {string} userId -
	 */
	GetLoginCode(userId: string): Promise<LoginCode[]> {
		return this.request('GET', `${baseUrl}/admin/users/codes/${userId}`);
	}
	/**
	 * Gets users by organization id
	 * @method
	 * @name AdminApi#GetUsersByOrganization
	 * @param {string} organizationId -
	 */
	GetUsersByOrganization(organizationId: string, pagination: { current?: number; pageSize?: number }, testUser = false): Promise<{ items: AdminUser[]; total: number }> {
		return this.request('GET', `${baseUrl}/admin/users/organization/${organizationId}?current=${pagination.current}&pageSize=${pagination.pageSize}&testUser=${testUser}`);
	}
	/**
	 * Get users by organization and location id
	 * @method
	 * @name AdminApi#GetUsersByOrganizationAndLocations
	 * @param {string} organizationId -
	 * @param {string} locationId -
	 */
	GetUsersByOrganizationAndLocations(organizationId: string, locationId: string, pagination: { current?: number; pageSize?: number }, testUser = false): Promise<{ items: AdminUser[]; total: number }> {
		return this.request('GET', `${baseUrl}/admin/users/organization/${organizationId}/location/${locationId}?current=${pagination.current}&pageSize=${pagination.pageSize}&testUser=${testUser}`);
	}

	GetUsersByAdminId(): Promise<User[]> {
		return this.request('GET', `${baseUrl}/admin/users/recent/users`);
	}

	/**
	 * Create a new user
	 * @method
	 * @name AdminApi#CreateUser
	 * @param {} requestBody -
	 */
	CreateUser(requestBody: User): Promise<UserLoginCode> {
		return this.request('POST', `${baseUrl}/admin/users`, requestBody);
	}
	/**
	 * Create a new user
	 * @method
	 * @name AdminApi#SetUserPassword
	 * @param {string} userId -
	 * @param {object} requestBody -
	 */
	SetUserPassword(
		userId: string,
		requestBody: {} & {
			[key: string]: any;
		}
	): Promise<Success> {
		return this.request('PUT', `${baseUrl}/admin/users/setPassword/${userId}`, requestBody);
	}
	/**
	* Remove all users
TODO: ONLY ADMINS CAN DO THIS AND NOT IN PRODUCTION, NEEDS TO BE IMPLEMENTED
	* @method
	* @name AdminApi#DeleteUsers
	*/
	DeleteUsers(): Promise<void> {
		return this.request('DELETE', `${baseUrl}/admin/users/users`);
	}
	/**
	 * Returns the user id by looking up a code
	 * @method
	 * @name AdminApi#GetUserIdFromCode
	 * @param {string} code -
	 */
	GetUserIdFromCode(code: string): Promise<LoginCode> {
		return this.request('GET', `${baseUrl}/app/codes/${code}`);
	}
	/**
	 * Get user info with features and settings based on userId in the JWT token
	 * @method
	 * @name AdminApi#GetUserInfoJWT
	 */
	GetUserInfoJWT(): Promise<UserInfoSettings> {
		return this.request('GET', `${baseUrl}/users`);
	}
	/**
	 * Updates userinformation from the client, such as onboarding
	 * @method
	 * @name AdminApi#UpdateUserInfo
	 * @param {} requestBody -
	 */
	UpdateUserInfo(requestBody: UserInfoPayload): Promise<User> {
		return this.request('PUT', `${baseUrl}/users`, requestBody);
	}
	/**
	 *
	 * @method
	 * @name AdminApi#GetVersion
	 */
	GetVersion(): Promise<void> {
		return this.request('GET', `${baseUrl}/admin/version`);
	}

	/**
	 * Create new User Login Code
	 * @method
	 * @name AdminApi#addUserLoginCode
	 * @param {} requestBody -
	 */

	AddUserLoginCode(userId: string): Promise<LoginCode> {
		return this.request('GET', `${baseUrl}/admin/users/${userId}/loginCode`);
	}
}
const adminApi = new AdminApi();
export default adminApi;
