import { Auth } from 'aws-amplify';
import { findCookie, ACCESS_TOKEN_COOKIE } from '../utils';

// export const AWS_REGION = "eu-west-2";
const {
	REACT_APP_AWS_REGION,
	REACT_APP_AWS_COGNITO_USER_POOL_ID,
	REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
	REACT_APP_AWS_API_GATEWAY_NAME,
	REACT_APP_AWS_API_URL,
} = window.__RUNTIME_CONFIG__;

export const API_GATEWAY_NAME = `${REACT_APP_AWS_API_GATEWAY_NAME}`;

export const AWS_CONFIG = {
	Auth: {
		// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
		// identityPoolId: `${REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID}`,

		// REQUIRED - Amazon Cognito Region
		region: `${REACT_APP_AWS_REGION}`,

		// OPTIONAL - Amazon Cognito Federated Identity Pool Region
		// Required only if it's different from Amazon Cognito Region
		// identityPoolRegion: 'XX-XXXX-X',

		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: `${REACT_APP_AWS_COGNITO_USER_POOL_ID}`,

		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: `${REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID}`,

		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
		mandatorySignIn: false,

		// OPTIONAL - Configuration for cookie storage
		// Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
		cookieStorage: {
			// REQUIRED - Cookie domain (only required if cookieStorage is provided)
			domain: window.location.hostname, // ".patient-companion.compasspathways.com, localhost", //
			// OPTIONAL - Cookie path
			path: '/',
			// OPTIONAL - Cookie expiration in days
			expires: 365,
			// OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
			sameSite: 'strict',
			// OPTIONAL - Cookie secure flag
			// Either true or false, indicating if the cookie transmission requires a secure protocol (https).
			secure: false,
		},

		// OPTIONAL - customized storage object
		// storage: MyStorage,

		// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
		authenticationFlowType: 'CUSTOM_AUTH', // "USER_PASSWORD_AUTH",

		// OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
		// clientMetadata: { myCustomKey: 'myCustomValue' },

		// OPTIONAL - Hosted UI configuration
		// oauth: {
		//   domain: "your_cognito_domain",
		//   scope: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
		//   redirectSignIn: "http://localhost:3000/",
		//   redirectSignOut: "http://localhost:3000/",
		//   responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
		// },
	},
	// aws_cloud_logic_custom: [
	//   {
	//     name: API_GATEWAY_NAME, // (required) - API Name (This name is used used in the client app to identify the API - API.get('your-api-name', '/path'))
	//     endpoint: `https:/09ugfjne37.execute-api.${AWS_REGION}.amazonaws.com`, // (required) -API Gateway URL + environment
	//     region: AWS_REGION, // (required) - API Gateway region
	//   },
	// ],
	API: {
		endpoints: [
			{
				name: API_GATEWAY_NAME,
				endpoint: REACT_APP_AWS_API_URL,
				custom_header: () => {
					const accessToken = findCookie({ cookieName: ACCESS_TOKEN_COOKIE, cookie: document.cookie })

					return { Authorization: `Bearer ${accessToken}` };
				},
			},
		],
	},
	Storage: {
		AWSS3: {
			bucket: 'compasspathways-documents', //REQUIRED -  Amazon S3 bucket name
			region: `${REACT_APP_AWS_REGION}`, //OPTIONAL -  Amazon service region
		},
	},
};
