import React, { FC, Suspense, lazy, useEffect, useCallback } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useAppContext } from '../contexts/app.context';
import BulkLocations from '../features/locations/BulkLocations';
import Locations from '../features/locations/Locations';
import BulkUsers from '../features/users/BulkUsers';
import { ampli, TrackEventType } from '../services/ampliService';
// lazy load / code split the feature modules
const Home = lazy(() => import('../features/home/Home'));
const ParticipantDetails = lazy(() => import('../features/participants/ParticipantDetails'));
const Participants = lazy(() => import('../features/participants/Participants'));
const StudyList = lazy(() => import('../features/study/StudyList'));
const StudyWrapper = lazy(() => import('../features/study/StudyWrapper'));
const Users = lazy(() => import('../features/users/Users'));
const NoMatch = lazy(() => import('./NoMatch'));

export interface RouteObj {
	path: string;
	paths?: string[];
	key: string;
	component: React.ComponentType<any>;
	menuKey?: string;
}
const homeRoute = { path: '/', key: 'home', component: Home };
const usersRoute = { path: '/adminusers', key: 'adminusers', component: Users };
const bulkUsersRoute = { path: '/adminusersbulk', key: 'adminusersbulk', component: BulkUsers };
const bulkLocationsRoute = { path: '/locationsbulk', key: 'locationsbulk', component: BulkLocations };
const locationsRoute = { path: '/locations', key: 'locations', component: Locations };
const studyRoute = { path: '/study', key: 'study', component: StudyList };
const studyAddRoute = { path: '/study/details', key: 'studyadd', component: StudyWrapper };
const studyDetailsRoute = { path: '/study/details/:organizationId', key: 'studydetails', component: StudyWrapper };
const participantsRoute = {
	path: '/participants',
	paths: ['/participants', '/participants/:organizationId?', '/participants/:organizationId/:locationId?'],
	key: 'participants',
	menuKey: 'participants',
	component: Participants,
};
const participantRoute = {
	path: '/participant/:id',
	key: 'participant',
	menuKey: 'participants',
	component: ParticipantDetails,
};

export const routeNames: { [key: string]: RouteObj } = {
	homeRoute,
	usersRoute,
	bulkUsersRoute,
	bulkLocationsRoute,
	locationsRoute,
	studyRoute,
	studyAddRoute,
	studyDetailsRoute,
	participantsRoute,
	participantRoute,
};
export const routes = [...Object.keys(routeNames).map((x) => routeNames[x] as RouteObj)];

const findMenuItem = (path: string) => routes.find((x) => x.key === path);

export const findMenuKey = (path: string) => {
	let menuKey = homeRoute.key;
	const newPathArr = path.split('/');
	if (newPathArr.length) {
		const newPath = newPathArr[1];
		const menuItem = findMenuItem(newPath);
		if (menuItem) {
			menuKey = menuItem.menuKey || menuItem.key;
		}
	}
	// console.log(`path=${path} - menuKey=${menuKey}`);
	return menuKey;
};

const Routes: FC = () => {
	const { userInfo } = useAppContext();
	const history = useHistory();

	const handleTrackEvent = useCallback(
		(route: string) => {
			ampli.track({
				eventType: TrackEventType.ROUTE_VISITED,
				eventPayload: {
					route: route,
					userId: userInfo?.id,
				},
			});
		},
		[userInfo?.id]
	);

	useEffect(() => {
		const unlisten = history.listen((location) => {
			handleTrackEvent(location.pathname);
		});
		return function cleanup() {
			unlisten();
		};
	}, [history, handleTrackEvent]);

	return (
		<Suspense fallback={<div>Loading...</div>}>
			<Switch>
				{routes.map((r) => (
					<Route exact key={r.key} path={r.paths || r.path} component={r.component} />
				))}
				<Route path="*">
					<NoMatch />
				</Route>
			</Switch>
		</Suspense>
	);
};

export default Routes;
