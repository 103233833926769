import React, { FC, useState } from 'react';
import { UploadOutlined, ArrowLeftOutlined, DownloadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Row, Select, Table, Upload, UploadProps, message, Alert, Popover } from 'antd';
import { AdminUser, BulkUser } from '../../services/adminApiService';
import Page from '../../components/Page';
import Column from 'antd/lib/table/Column';
import API from '../../services/adminApiService';
import './users.css';
import { fieldNamesAdminUsers, parseCSV } from '../../services/csv-parser.service';
import { useAppContext } from '../../contexts/app.context';
import FeedbackFormService from '../../services/feedbackFormService';
import { Link } from 'react-router-dom';
import { routeNames } from '../../routes/routes';
import { CSVLink } from 'react-csv';

const csvHelpContent = (
	<div>
		<p>Download the CSV template and edit it.</p>
		<h3>Columns</h3>
		<p>
			<b>email: </b>*User id based on email.<br />
			<b>phone: </b>Mobile phone number including country code (ex. us: +14159901234) <br />
			<b>firstname: </b>First name<br />
			<b>lastname: </b>Last name<br />
			<b>sitenumber: </b>*Site number to associate to the user. Only one site number is supported at the moment. Leave empty for all sites.<br />
		</p>
		<p>Once uploaded, edit the roles, protocol, and active.</p>
		<p>Press save at the bottom of the screen to save the changes into the system.</p>
		<p>You cannot remove any data with the CSV upload.</p>
	</div>
);
const csvData = [
	[fieldNamesAdminUsers.email, fieldNamesAdminUsers.phone, fieldNamesAdminUsers.firstName, fieldNamesAdminUsers.lastName, fieldNamesAdminUsers.siteId],
	["john.smith@compasspathways.com", "+14159909999", "John", "Smith", "0", "New York, NY"],
];

const actions = (uploadSettings: UploadProps) => (
	<Row gutter={8}>
		<Col>
			<Popover placement="bottomLeft" title={"CSV Upload Information"} content={csvHelpContent} trigger="click">
				<Button icon={<QuestionCircleOutlined />}>CSV Help</Button>
			</Popover>
		</Col>
		<Col>
		<CSVLink data={csvData} filename="admin-users.csv" >
				<Button type="default" >
					<DownloadOutlined /> Download CSV Template
				</Button>
			</CSVLink>
		</Col>
		<Col>
			<Upload {...uploadSettings}>
				<Button type="primary" icon={<UploadOutlined />}>
					Add Bulk Users (CSV)
				</Button>
			</Upload>
		</Col>
	</Row>
);

const BulkUsers: FC = () => {
	const { organizations, roles } = useAppContext();

	FeedbackFormService.clear();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [csvRows, setCSVRows] = useState<any[] | undefined>();
	const [adminsSaved, setAdminsSaved] = useState<AdminUser[]>([]);

	const uploadProps: UploadProps = {
		multiple: false,
		maxCount: 1,
		beforeUpload: (file) => {
			const isValidFile = file.type === 'text/csv';
			if (!isValidFile) {
				message.error(`${file.name} is not a valid CSV file`);
				return Upload.LIST_IGNORE;
			}
			// not uploading ref: https://ant.design/components/upload
			return false;
		},
		onChange: async (info) => {
			if (info.file.status === 'removed') {
				setCSVRows(undefined);
				return;
			}
			const m: any | any[] = await parseCSV(info.file, "users");
			setCSVRows(m);
		},
	};

	const handleBulkUsers = async (values: any) => {
		if (!Array.isArray(csvRows) || !csvRows) {
			return;
		}
		const { organizationIds, active, roles } = values;
		const users: BulkUser[] = csvRows.map((row) => ({
			firstName: row.firstName,
			lastName: row.lastName,
			site_number: row.siteId,
			active: active ? true : false,
			role: roles,
			email: row.email.trim(),
			organizationIds,
		}));
		setIsLoading(true);
		
		const admins = await API.AddBulkAdminUsers(users);
		if (admins) {
			setIsLoading(false);
			setAdminsSaved(admins);
			setCSVRows([]);
		}
	};

	const csvFooter = () => {
		return (
			<Form name="bulk" layout="inline" onFinish={handleBulkUsers}>
				<Form.Item label="Protocols" name="organizationIds" rules={[{ required: true, message: 'Please select a Protocol' }]}>
					<Select mode="multiple" style={{ width: 200, margin: '0 8px' }}>
						{organizations?.map((org) => (
							<Select.Option key={org._id} value={org._id!}>
								{org.name}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label="Roles" name="roles" rules={[{ required: true, message: 'Please select a role' }]}>
					<Select style={{ width: 200, margin: '0 8px' }}>
						{roles &&
							roles.map((r) => (
								<Select.Option key={r} value={r}>
									{r}
								</Select.Option>
							))}
					</Select>
				</Form.Item>
				<Form.Item label="Active" name="active" valuePropName="checked">
					<Checkbox />
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit">
						Save
					</Button>
				</Form.Item>
			</Form>
		);
	};

	const SaveWarning = () => <div style={{ padding: 10 }}><Alert message="Users not saved yet, make sure you click Save after assigning Protocol(s) and Role(s)" type="warning" /></div>;
	const UploadSuccess = () => <div style={{ padding: 10 }}><Alert message={`${adminsSaved.length} user(s) was uploaded successfully`} type="info" /></div>;
	const renderTable = () => {
		return (
			<>
				{csvRows && adminsSaved.length === 0 ? <SaveWarning /> : null}
				{csvRows && adminsSaved.length ? <UploadSuccess /> : null}
				<Table dataSource={csvRows} rowKey="email" footer={csvFooter} loading={isLoading} locale={{ emptyText: (<div>Click the Add Bulk users botton in the top right corner to upload CSV</div>) }}>
					<Column title="Email" key={`${fieldNamesAdminUsers.email}`} dataIndex={`${fieldNamesAdminUsers.email}`} />
					<Column title="Phone" key={`${fieldNamesAdminUsers.phone}`} dataIndex={`${fieldNamesAdminUsers.phone}`} />
					<Column title="First Name" key={`${fieldNamesAdminUsers.firstName}`} dataIndex={`${fieldNamesAdminUsers.firstName}`} />
					<Column title="Last Name" key={`${fieldNamesAdminUsers.lastName}`} dataIndex={`${fieldNamesAdminUsers.lastName}`} />
					<Column title="Site Number" key={`${fieldNamesAdminUsers.siteId}`} dataIndex={`${fieldNamesAdminUsers.siteId}`} />
				</Table>
			</>
		);
	};

	return (
		<Page title="Bulk Upload" actions={actions(uploadProps)} description="Upload multiple users from a .csv file. Use the 'standard' format and convert to .csv">
			<span style={{ paddingBottom: 10, marginTop: -20 }}><Link to={routeNames.usersRoute.path}><ArrowLeftOutlined /> Back to users</Link></span>
			<div>{renderTable()}</div>
		</Page>
	);
};
export default BulkUsers;
