import React, { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import { Layout, Menu } from 'antd';
import './siteLayout.css';
import logo from '../assets/images/compass_white_notag.png';
import Routes, { findMenuKey, routeNames } from '../routes/routes';
import {
	VideoCameraOutlined,
	HomeOutlined,
	MobileOutlined,
	TeamOutlined,
	ShopOutlined,
	UserOutlined,
	//  FolderOpenOutlined
} from '@ant-design/icons';
import { useAppContext } from '../contexts/app.context';
import { Role, Roles } from '../config/constants';

const { Content, Footer, Sider } = Layout;

const authIssuerUrl = window.__RUNTIME_CONFIG__.AUTH_ISSUER_URL;

interface LayoutProps {
	signOutHandler: () => void;
}

const SiteLayout: FC<LayoutProps> = ({ signOutHandler }) => {
	const { userInfo } = useAppContext();
	const [menuKey, setMenuKey] = useState<string>(routeNames.homeRoute.key);
	const { pathname } = useLocation();

	const restrictedLinks = () => {
		const items = [];
		if (userInfo?.role) {
			if (([Roles.SUPER_ADMIN, Roles.STUDY_ADMIN, Roles.STUDY_COORDINATOR] as Array<Role>).includes(userInfo.role)) {
				items.push(<Menu.Item key={routeNames.participantsRoute.menuKey} icon={<MobileOutlined />}>
					<Link to={routeNames.participantsRoute.path}>Participants</Link>
				</Menu.Item>);
				items.push(<Menu.Divider key={"divider1"} />);
			}

			if (([Roles.SUPER_ADMIN, Roles.STUDY_ADMIN] as Array<Role>).includes(userInfo.role)) {
				items.push(<Menu.Item key={routeNames.studyRoute.key} icon={<VideoCameraOutlined />}>
					<Link to={routeNames.studyRoute.path}>Protocols</Link>
				</Menu.Item>);
				items.push(<Menu.Item key={routeNames.locationsRoute.key} icon={<ShopOutlined />}>
					<Link to={routeNames.locationsRoute.path}>Institutions</Link>
				</Menu.Item>);
				items.push(<Menu.Item key={routeNames.usersRoute.key} icon={<TeamOutlined />}>
					<Link to={routeNames.usersRoute.path}>Admin Users</Link>
				</Menu.Item>);
			}
		}
		return items;
	};
	useEffect(() => {
		setMenuKey(findMenuKey(pathname));
	}, [pathname]);

	// console.log(`sitelayout currentPath=${menuKey}`);
	return (
		<Layout className="site-layout">
			<Sider
				breakpoint="lg"
				collapsedWidth="0"
				onBreakpoint={(broken) => {
					console.log(broken);
				}}
				onCollapse={(collapsed, type) => {
					console.log(collapsed, type);
				}}
			>
				<div className="logo">
					<Link to={routeNames.homeRoute}>
						<img src={logo} alt="COMPASS Pathways" width={150} />
					</Link>
				</div>
				<Menu theme="dark" mode="inline" selectedKeys={[menuKey]}>
					<Menu.Item key={routeNames.homeRoute.key} icon={<HomeOutlined />}>
						<Link to={routeNames.homeRoute.path}>Home</Link>
					</Menu.Item>
					{restrictedLinks()}
					<Menu.Item key={"profile"} icon={<UserOutlined />}>
						<a href={authIssuerUrl}>My account</a>
					</Menu.Item>
				</Menu>
				<br />
				<Button type="primary" onClick={signOutHandler}>
					Sign Out
				</Button>
			</Sider>
			<Layout className="layout-wrapper">
				{/* <Header className="site-layout-sub-header-background">Header</Header> */}
				<Content className="site-layout-content">
					<div className="site-layout-background">
						<Routes />
					</div>
				</Content>
				<Footer className="site-layout-footer">&copy; COMPASS Pathways {new Date().getFullYear()}</Footer>
			</Layout>
		</Layout>
	);
};

export default SiteLayout;
