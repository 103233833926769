// set the pre-fill values for the atlassian feedback form found in the index.html file
export default class FeedbackFormService {
    static setEmail(email?: string) {
        try {
            window.feedbackFormInfo[window.FEEDBACK_FORM_ID].fieldValues.email = email || "";
        } catch (ex) {
            console.log('setting feedback form email values error', ex);
        }

    }
    // todo: implement
    static setOrgInstitution(value?: string) {
        try {
            window.feedbackFormInfo[window.FEEDBACK_FORM_ID].fieldValues.fullname = value || "";
        } catch (ex) {
            console.log('setting feedback form org values error', ex);
        }
    }

    static setSummaryDescription(summary?: string, description?: string) {
        try {
            window.feedbackFormInfo[window.FEEDBACK_FORM_ID].fieldValues.summary = summary || "";
            window.feedbackFormInfo[window.FEEDBACK_FORM_ID].fieldValues.description = description || "";
        } catch (ex) {
            console.log('setting feedback form summary description values error', ex);
        }
    }

    // clear values except for email
    static clear() {
        this.setSummaryDescription("", "");
        this.setOrgInstitution("");
    }
}
