import { API } from 'aws-amplify';
import axios from 'axios';

import { API_GATEWAY_NAME } from '../config/aws';
import { showNotification } from './notificationService';
import { get } from 'lodash';
import { findCookie, ACCESS_TOKEN_COOKIE } from '../utils';



const getPath = (path: string) => path;
export class BaseService {
	async request(method: string, path: string, body?: any) {
		const useLocalApi = window.__RUNTIME_CONFIG__.REACT_APP_USE_LOCAL_API === 'true';

		const accessToken = findCookie({ cookieName: ACCESS_TOKEN_COOKIE, cookie: document.cookie })
		const requestConfig = { headers: { Authorization: `Bearer ${accessToken}` } };


		let request;
		let notification = false;

		// use axios for url requests if using localhost API
		if (useLocalApi) {
			const baseUrl = window.__RUNTIME_CONFIG__.REACT_APP_LOCAL_BASE_URL;
			const reqPath = path.startsWith('http') ? path : `${baseUrl}${path}`;

			switch (method) {
				case 'GET':
					request = axios.get(`${reqPath}`, requestConfig);
					break;
				case 'DELETE':
					request = axios.delete(`${reqPath}`, requestConfig);
					break;
				case 'POST':
					request = axios.post(`${reqPath}`, body, requestConfig);
					notification = true;
					break;
				case 'PUT':
					request = axios.put(`${reqPath}`, body, requestConfig);
					notification = true;
					break;
			}

			// Use API Gateway and AWS Amplify lib
		} else {
			path = getPath(path);
			switch (method) {
				case 'GET':
					request = API.get(API_GATEWAY_NAME, path, requestConfig);
					break;
				case 'DELETE':
					request = API.del(API_GATEWAY_NAME, path, requestConfig);
					break;
				case 'POST':
					request = API.post(API_GATEWAY_NAME, path, { ...requestConfig, body });
					notification = true;
					break;
				case 'PUT':
					request = API.put(API_GATEWAY_NAME, path, { ...requestConfig, body });
					notification = true;
					break;
			}
		}
		if (!request) {
			showNotification('error', 'Invalid request type', `No valid request method, expected GET, POST, PUT or DELETE. Found:${method}`);
			throw Error(`No valid request method, expected GET,POST,PUT or DELETE`);
		}
		try {
			let data: any;
			if (useLocalApi) {
				const tmpData = await request;
				data = tmpData.data;
			} else {
				data = await request;
			}
			if (notification) {
				showNotification('success', 'Request successful');
			}
			return data;
		} catch (error) {
			console.log(`ERROR`, error);

			let { message, subject } = this.processError(error);
			// console.log(`Message`, message);
			// console.log(`Subject`, subject);
			if (message.errorMessage) {
				message = message.errorMessage;
			}
			showNotification('error', subject, message);
		}
		return false;
	}

	processError(error: any) {
		console.log(`typeof error`, typeof error);

		let rtnMessage: any = '';
		let subject = '';
		if (error.response) {
			// Request made and server responded
			console.log(`error response`, error.response);
			const { data, status } = error.response;
			subject = data.message ?? `API Request error ${status}`;
			const message = get(data, ['details', 'requestBody', 'message'], data);

			if (message.details) {
				console.log(`Error message.details:`, JSON.stringify(message.details));
				Object.keys(message.details).forEach((x) => {
					const msg = get(message.details, [x, 'message']);
					if (msg) {
						rtnMessage = `${rtnMessage}\n${msg}`;
					}
				});
				rtnMessage = rtnMessage === '' ? message.details : rtnMessage;
			} else if (message.message) {
				console.log('else message.message', message.message);

				rtnMessage = rtnMessage === '' ? message.message : rtnMessage;
			} else if (message) {
				console.log('else message', message);
				rtnMessage = rtnMessage === '' ? message : rtnMessage;
			}
		} else if (error.request) {
			console.log('else error.request', error.toString());
			subject = 'Error, see more in logs';
			rtnMessage = error.toString();
			// The request was made but no response was received
			console.log(error.request);
		} else {
			console.log('else ', error);
			subject = 'Error, see more in logs';
			rtnMessage = error.toString();
			// Something happened in setting up the request that triggered an Error
			console.log('ELSE ERROR', error);
			console.log('ELSE rtnMessage', rtnMessage);
		}

		return { subject, message: rtnMessage };
	}
}
