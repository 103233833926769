import { notification } from "antd";

export type NoticeMethods = "success" | "info" | "warning" | "error";

// {
//   success = "success",
//   info = "info",
//   warning = "warning",
//   error = "error"
// }
export const showNotification = (type: NoticeMethods, message: string, description?: string) => {
  (notification as any)[type]({
    message,
    description: description || "",
  });
};
