import { createContext, useContext, FC, useEffect, useState } from 'react';
import API, { Organization, OrganizationLocation, OrganizationSettings } from '../services/adminApiService';
import FeedbackFormService from '../services/feedbackFormService';
import { CurrentUserData, useCurrentUserApi } from '../services/graphql/currentUser/useCurrentUserApi';
import { AccountRoles, Roles, Role, rolesList } from '../config/constants';

type UserInfo = Omit<CurrentUserData["me"], "roles"> & { role: Role | null } & CurrentUserData["currentUser"] | null

interface AppContextSchema {
	userInfo: UserInfo;
	roles: Array<Role>;
	locations: OrganizationLocation[] | undefined;
	organizations: Organization[] | undefined;
	getOrganization: (id: string) => Organization | undefined;
	getOrganizations: () => Promise<Organization[] | undefined>;
	getOrganizationSettings: (id: string) => OrganizationSettings | undefined;
	updateOrganization: (id: string, newOrg: Organization) => void;
	getLocations: () => Promise<OrganizationLocation[] | undefined>;
}

export const AppContext = createContext<AppContextSchema | undefined>(undefined);

export const extractAndMapRole = ({ roles }: { roles: Array<string> }) => {
	const adminPortalRoles = roles.filter((role) => role.startsWith('mypathfinder-admin;'));

	/**
	 * In the event a user has more than one admin portal role provided by the
	 * accounts portal, we move through the roles in order of
	 * most to least permissive
	 */
	if (adminPortalRoles.includes(AccountRoles.SUPER_ADMIN)) {
		return Roles.SUPER_ADMIN;
	}

	if (adminPortalRoles.includes(AccountRoles.STUDY_ADMIN)) {
		return Roles.STUDY_ADMIN;
	}

	if (adminPortalRoles.includes(AccountRoles.STUDY_COORDINATOR)) {
		return Roles.STUDY_COORDINATOR;
	}

	return null;
};

export const AppContextProvider: FC = ({ children }) => {
	const { currentUser, isCurrentUserLoading } = useCurrentUserApi();
	const [organizations, setOrganizations] = useState<Organization[] | undefined>(undefined);
	const [locations, setLocations] = useState<OrganizationLocation[] | undefined>(undefined);

	const role = currentUser?.me.roles ? extractAndMapRole({ roles: currentUser?.me.roles }) : null

	const getLocations = async (): Promise<OrganizationLocation[] | undefined> => {
		const locs = await API.GetLocations();
		if (!locs) {
			return [];
		}
		const locations = locs.sort((a, b) => (a.name.toLowerCase()! > b.name.toLocaleLowerCase()! ? 1 : b.name.toLocaleLowerCase()! > a.name.toLowerCase()! ? -1 : 0));
		setLocations(locations);
		return locations;
	};


	const getOrganizations = async (): Promise<Organization[] | undefined> => {
		const orgs = await API.GetOrganizations();
		setOrganizations(orgs);
		return orgs;
	};

	const getOrganization = (organizationId: string): Organization | undefined => {
		if (!organizations) return;
		if (!organizationId) return;
		const org = organizations.find((organization) => organization._id === organizationId);
		return org;
	};

	const updateOrganization = (organizationId: string, newOrg: Organization): void => {
		if (!organizations) return;
		if (!organizationId) return;
		const orgIndex = organizations.findIndex((organization) => organization._id === organizationId);
		if (orgIndex > -1) {
			const updatedOrg = {
				...organizations[orgIndex],
				...newOrg,
			};
			organizations[orgIndex] = updatedOrg;
			setOrganizations(organizations);
		}
	};

	const getOrganizationSettings = (organizationId: string): OrganizationSettings | undefined => {
		if (!organizations) return;
		if (!organizationId) return;
		const org = organizations.find((organization) => organization._id === organizationId);
		if (org) {
			return { organizationSettings: { ...org.organizationSettings } } as OrganizationSettings;
		}
		return;
	};

	useEffect(() => {
		(async () => {
			if (currentUser && !isCurrentUserLoading) {
				await getOrganizations();
				await getLocations();
				// set basic feedback form information
				FeedbackFormService.setEmail(currentUser.currentUser.email);
			}
		})();
	}, [currentUser, isCurrentUserLoading]);

	const values = {
		userInfo: currentUser ? { ...currentUser.currentUser, ...currentUser.me, role } : null,
		roles: rolesList,
		locations,
		organizations,
		getOrganization,
		getOrganizations,
		updateOrganization,
		getOrganizationSettings,
		getLocations,
	};

	return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export function useAppContext() {
	const context = useContext(AppContext);
	if (!context) {
		throw new Error('AppContext is not defined!');
	}
	return context!;
}
