import Amplitude, { AmplitudeClient, Config } from 'amplitude-js';

export interface DefaultAmpliOptions {
	environment?: 'development' | 'production';
	client?: {
		apiKey: string;
		config?: Config;
	};
}

export enum TrackEventType {
	'ROUTE_VISITED' = 'ROUTE_VISITED',
}

export interface TrackEvent {
	eventType: TrackEventType;
	eventPayload: {
		userId?: string;
		route?: string;
		ip?: string;
		[key: string]: any;
	};
}

class Ampli {
	private disabled = true;
	private client?: AmplitudeClient;

	load(options?: DefaultAmpliOptions): void {
		if (this.client) {
			console.error(`Amplitude is already defined!`);
			return;
		}

		const apiKey = options?.client?.apiKey ?? window.__RUNTIME_CONFIG__.REACT_APP_AMPLITUDE_API_KEY;

		if (!apiKey) {
			console.error(`Amplitude key should be defined!`, apiKey);
			// throw new Error(`Amplitude key should be defined!`);
			return;
		}

		this.client = Amplitude.getInstance();
		this.client.init(apiKey, undefined, { ...options?.client?.config });
		this.disabled = false;
	}

	track(event: TrackEvent): void {
		if (this.disabled) {
			return;
		}
		this.client?.logEvent(event.eventType, { ...event.eventPayload });
	}
}

export const ampli = new Ampli();
