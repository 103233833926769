import React, { FC } from "react";
import "./page.css";
type PageProps = {
  title: string;
  description?: string;
  actions?: any;
};
const Page: FC<PageProps> = ({ title, description, actions, children }) => {
  return (
    <div>
      <span className="page-title">{title}</span>
      <span className="page-actions">{actions}</span>
      {description ? <p>{description}</p> : null}
      <div className="page-content">{children}</div>
    </div>
  );
};
export default Page;
