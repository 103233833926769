import { gql, useQuery } from "@apollo/client";
import { Institution, Organization } from "../types";
import { logout } from "@compasspathways/gjallarhorn";
import { showNotification } from '../../notificationService';

const CURRENT_USER_QUERY = gql(`
  query CurrentUser {
    currentUser {
      id
      email
      phone
      firstName
      lastName
      name
      createdAt
      updatedAt
      institutions {
        id
        name
        siteNumber
        organization {
          id
        }
      }
      organizations {
        id
        name
        protocolId
        code
        createdAt
      }
    }
    me {
      __typename
      ... on UserIdentity {
        roles
        permissions
      }
    }
  }
`);

/**
 * TODO: consider adding typegen
 */
export type CurrentUserData = {
  currentUser: {
    id: string
    email: string
    phone: string
    firstName: string
    lastName: string
    name: string
    createdAt: string
    updatedAt: string
    institutions: Array<Institution>
    organizations: Array<Organization>
  },
  me: {
    __typename: "UserIdentity";
    permissions: Array<string>
    roles: Array<string>
  };
};

const authIssuerUrl = window.__RUNTIME_CONFIG__.AUTH_ISSUER_URL;

export const useCurrentUserApi = () => {
  const { data: currentUser, loading: isCurrentUserLoading } = useQuery<CurrentUserData>(CURRENT_USER_QUERY, {
    onError(error) {
      console.error(error);

      const errorCode = error.graphQLErrors.at(0)?.extensions?.code;

      switch (errorCode) {
        /**
         * If this occurs, we likely failed to refresh the token,
         * so we force a logout
         */
        case "AUTHORIZATION_ERROR":
          logout({ authIssuerUrl })

          return;

        default:
          showNotification('error', 'Failed to load session. Please try again later.');

          return;
      }
    },
  });

  if (!isCurrentUserLoading && currentUser?.me.__typename !== "UserIdentity") {
    throw new Error(`Invalid identity type: ${currentUser?.me.__typename}`);
  }

  return {
    currentUser,
    isCurrentUserLoading,
  };
};
