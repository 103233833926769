import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { ApolloClientWithCache } from "./types";

const GRAPHQL_ORIGIN = window.__RUNTIME_CONFIG__.GRAPHQL_ORIGIN ?? "/graphql";

export const initializeApolloClient = (): ApolloClientWithCache => new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: GRAPHQL_ORIGIN,
    credentials: "include",
  })
})
